import { api as libraryApi } from 'api/librariesApi'
import { AxiosResponse } from 'axios'
import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { Availability } from 'common'
import create, { GetState, SetState, State } from 'zustand'

type LibrarySplash = {
  backgroundColor: string
  displayText: string
  enabled: boolean
  imageName: string
  libraryId: number
  seconds: number
  textColor: string
}

type Rating = {
  id: number
  display: boolean
  rank: number
  rating: string
  ratingSystemId: number
}

type LibraryRatings = {
  libraryId: number
  comicRatings: Rating[]
  movieRatings: Rating[]
  televisionRatings: Rating[]
}

interface UseLibraryState extends State {
  library?: Library
  libraryRatings?: LibraryRatings
  librarySplashSettings?: LibrarySplash

  fetchLibrary: (libraryId: number) => void
  fetchLibraryRatings: (libraryId: number) => void
  fetchLibrarySplash: (libraryId: number) => void
  getDefaultAvailabilityFilter: () => Availability
  teardown: () => void
}

export const useLibrary = create(
  (
    set: SetState<UseLibraryState>,
    get: GetState<UseLibraryState>,
  ): UseLibraryState => ({
    library: undefined,
    libraryRatings: undefined,
    librarySplashSettings: undefined,
    fetchLibrary: async (libraryId: number) => {
      try {
        const response: AxiosResponse<Library> = await libraryApi.fetchLibrary(
          libraryId,
        )
        set({ library: response.data })
      } catch (error) {
        Bugsnag.notify(error as NotifiableError)
      }
    },
    fetchLibraryRatings: async (libraryId: number) => {
      try {
        const response: AxiosResponse<LibraryRatings> =
          await libraryApi.fetchLibraryRatings(libraryId)
        set({ libraryRatings: response.data })
      } catch (error) {
        Bugsnag.notify(error as NotifiableError)
      }
    },
    fetchLibrarySplash: async (libraryId: number) => {
      try {
        const response: AxiosResponse<LibrarySplash> =
          await libraryApi.fetchLibrarySplash(libraryId)
        set({ librarySplashSettings: response.data })
      } catch (error) {
        Bugsnag.notify(error as NotifiableError)
      }
    },
    getDefaultAvailabilityFilter: () => {
      const defaultAvailability = get().library
        ?.defaultAvailabilityFilter as Availability

      const libraryHasAvailability =
        Object.values(Availability).includes(defaultAvailability)

      if (libraryHasAvailability) return defaultAvailability

      return get().library?.enableTitleRequests
        ? Availability.AllHooplaTitles
        : Availability.AllTitles
    },
    teardown: () => {
      set({
        library: undefined,
        libraryRatings: undefined,
        librarySplashSettings: undefined,
      })
    },
  }),
)
