import { useQuery, gql } from '@apollo/client'
import { client } from 'graphql/client'
import {
  TITLE_LIST_ITEM_FRAGMENT,
  TITLE_OVERLAY_FRAGMENT,
} from 'graphql/queries'

const HOLD_FRAGMENT = gql`
  fragment HoldFragment on Hold {
    id
    position
    positionPerCopy
    reserveUntil
    snoozeUntil
    suspendDays
    suspendUntil
    status
    zombie
  }
`

export const TRACK_FRAGMENT = gql`
  fragment TrackFragment on Track {
    contentId
    id
    mediaKey
    name
    seconds
    segmentNumber
  }
`

export const BUNDLED_CONTENT_FRAGMENT = gql`
  fragment BundledContentFragment on Content {
    id
    artKey
    captions {
      cc
    }
    episode
    episodeTitle
    issueNumberDescription
    kind {
      name
      singular
    }
    language {
      label
    }
    manga
    mediaKey
    overlay {
      ...TitleOverlayFragment
    }
    pages
    parentalAdvisory
    playbackPosition {
      lastPlayed
      percentComplete
    }
    primaryArtist {
      name
    }
    rating
    season
    seconds
    series {
      name
    }
    seriesNumberLabel
    synopsis
    title
    titleId
    trackCount
    tracks {
      ...TrackFragment
    }
    traditionalManga
    year
  }
  ${TRACK_FRAGMENT}
  ${TITLE_OVERLAY_FRAGMENT}
`

export const FETCH_TITLE_DETAIL = gql`
  query FETCH_TITLE_DETAIL($id: ID!, $includeDeleted: Boolean) {
    title(criteria: { id: $id, includeDeleted: $includeDeleted }) {
      abridged
      actors {
        id
        name
      }
      artKey
      authors {
        name
        id
      }
      bingePassType
      bisacs {
        id
        ancestors {
          id
          name
        }
        children {
          id
          name
          parent {
            id
            name
          }
        }
        name
        isParent
        parent {
          id
          name
        }
      }
      bundledContent {
        ...BundledContentFragment
      }
      captions {
        id
        cc
      }
      chapters {
        chapter
        duration
        ordinal
        start
        title
      }
      childrens
      circulation {
        borrowedDate
        dueDate
        id
        isRenewable
        licenseType
        maxDue
        patron {
          id
        }
        title {
          title
        }
      }
      copyright
      demo
      directors {
        name
        id
      }
      episodes {
        artKey
        circulation {
          borrowedDate
          dueDate
          id
          isRenewable
          licenseType
          patron {
            id
          }
        }
        episode
        id
        lastBorrowed
        lendingMessage
        licenseType
        mediaKey
        playbackPosition {
          percentComplete
          lastPlayed
        }
        seconds
        status
        synopsis
        subtitle
        title
      }
      externalCatalogUrl
      favorite
      fixedLayout
      genres {
        ancestors {
          id
          name
        }
        id
        name
      }
      hold {
        ...HoldFragment
      }
      holdsPerCopy
      id
      illustrators {
        id
        name
      }
      isbn
      issueNumberDescription
      kind {
        name
        id
        singular
        plural
      }
      language {
        name
        label
        id
      }
      lastBorrowed
      lendingMessage
      licenseType
      manga
      ${
        process.env.REACT_APP_FETCH_MARKETING_TEXT === 'true'
          ? 'marketingText'
          : ''
      }
      mediaKey
      mediaType
      overlay {
        ...TitleOverlayFragment
      }
      pages
      parentalAdvisory
      patronRating {
        stars
      }
      people {
        id
        name
        relationship
      }
      percentComplete
      playbackPosition {
        percentComplete
        lastPlayed
      }
      primaryArtist {
        name
        id
        similarArtists {
          id
          name
        }
      }
      producers {
        name
        id
      }
      profanity
      publisher {
        id
        name
      }
      rating
      readAlong
      readers {
        name
        id
      }
      relatedTitles {
        ...TitleListItemFragment
      }
      releaseDate
      reviews {
        id
        source
        quote
      }
      seconds
      seriesNumberLabel
      series {
        id
        name
      }
      status
      subtitle
      subtitleLanguage {
        name
      }
      synopsis
      tags {
        id
        name
      }
      title
      titleRating {
        totalCount
        weightedAverage
      }
      tracks {
        ...TrackFragment
      }
      traditionalManga
      writers {
        id
        name
      }
      year
      zombieHoldCount
      __typename
    }
  }
  ${BUNDLED_CONTENT_FRAGMENT}
  ${TRACK_FRAGMENT}
  ${TITLE_LIST_ITEM_FRAGMENT}
  ${HOLD_FRAGMENT}
  ${TITLE_OVERLAY_FRAGMENT}
`

export const FETCH_BORROW_INFO = gql`
  query FETCH_BORROW_INFO($id: ID!) {
    title(criteria: { id: $id }) {
      id
      circulation {
        borrowedDate
        dueDate
        id
        isRenewable
        licenseType
        patron {
          id
        }
        title {
          title
        }
        maxDue
      }
      episodes {
        circulation {
          borrowedDate
          dueDate
          id
          isRenewable
          licenseType
          patron {
            id
          }
        }
        id
        lendingMessage
        licenseType
        status
      }
      hold {
        ...HoldFragment
      }
      holdsPerCopy
      lastBorrowed
      lendingMessage
      licenseType
      status
      zombieHoldCount
      __typename
    }
  }
  ${HOLD_FRAGMENT}
`

export function useTitleDetail(
  id?: string,
  {
    includeDeleted = false,
    fetchPolicy = '',
    nextFetchPolicy = '',
    onCompleted,
    ...config
  }: {
    includeDeleted?: boolean
    fetchPolicy?: string
    nextFetchPolicy?: string
    onCompleted?: (title?: Title) => void
    config?: any
  } = {},
) {
  const { loading, data, error, refetch } = useQuery<{
    title: Title
  }>(FETCH_TITLE_DETAIL, {
    variables: {
      id,
      includeDeleted,
      fetchPolicy,
      nextFetchPolicy,
    },
    onCompleted: (data) => {
      onCompleted && onCompleted(data?.title)
    },
    errorPolicy: 'all',
    ...config,
  })

  return { loading, title: data?.title, error, refetch }
}

export function fetchTitleDetail(id: string) {
  return client.query<{ title: Title }>({
    query: FETCH_TITLE_DETAIL,
    variables: { id },
    errorPolicy: 'all',
  })
}

export function fetchTitleFromCache(id: string) {
  return client.readQuery<{ title: Title }>({
    query: FETCH_TITLE_DETAIL,
    variables: { id },
  })
}

export function fetchBorrowInfo(id: string) {
  return client.query<{ title: Title }>({
    query: FETCH_BORROW_INFO,
    variables: { id },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  })
}
