export type DateFormatItem = 'numeric' | '2-digit'
export type DateFormat = {
  month: DateFormatItem
  day: DateFormatItem
  year: DateFormatItem
}

export enum Availability {
  AllHooplaTitles = 'ALL_HOOPLA_TITLES',
  AllTitles = 'ALL_TITLES',
  AvailableNow = 'AVAILABLE_NOW',
  ComingSoon = 'COMING_SOON',
  TitleRequest = 'TITLE_REQUEST',
}

const isAvail = (value: any): value is Availability =>
  Object.values(Availability).includes(value)

export const isAvailability = (availabilityParam: Availability | undefined) =>
  isAvail(availabilityParam)

export enum DateLabel {
  SevenDays = 'LAST_7_DAYS',
  ThirtyDays = 'LAST_30_DAYS',
  ThreeMonths = 'LAST_3_MONTHS',
  SixMonths = 'LAST_6_MONTHS',
  TwelveMonths = 'LAST_12_MONTHS',
}

export enum HoldStatus {
  Approved = 'APPROVED',
  Borrowed = 'BORROWED',
  Denied = 'DENIED',
  Reserved = 'RESERVED',
  Requested = 'REQUESTED',
  Snoozed = 'SNOOZED',
  Suspended = 'SUSPENDED',
  Waiting = 'WAITING',
}

export enum KindName {
  Audiobook = 'AUDIOBOOK',
  Comic = 'COMIC',
  Ebook = 'EBOOK',
  Movie = 'MOVIE',
  Music = 'MUSIC',
  Television = 'TELEVISION',
  BingePass = 'BINGEPASS',
}

export enum LicenseType {
  EST = 'EST',
  None = 'NONE',
  PPU = 'PPU',
}

export enum Status {
  Borrowed = 'BORROWED',
  BorrowedInBundle = 'BORROWED_IN_BUNDLE',
  ComingSoon = 'COMING_SOON',
  ComingSoonHeld = 'COMING_SOON_HELD',
  ComingSoonNotAvailable = 'COMING_SOON_NOT_AVAILABLE',
  Held = 'HELD',
  Hold = 'HOLD',
  NotAvailable = 'NOT_AVAILABLE',
  Request = 'REQUEST',
  Requested = 'REQUESTED',
  None = 'NONE',
  Borrow = 'BORROW',
}
